<script>

import Uv from "@/components/uv/components/Uv.vue";

export default {
  components: {Uv}
}
</script>

<template>
  <div class="uv-page">
    <div class="pwd-red-packet-enter">
      <Uv uv-type="PASSWORD_RED_PACKET_ENTER"></Uv>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>